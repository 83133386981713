<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">常見問題管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions" @addNewRow="(row, callback) => { row.Ordinal = 0; row.Answer = {}; callback(); }">
          <template #modal="{ row, submit, reset }">
            <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
              <template v-if="row.Answer" #column-answer="{ data }">
                <CKEditor v-model="data.Answer.Content" v-bind="editorOptions" />
              </template>
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })">
            </vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      title: "常見問題管理",
      multiselect: false,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        {
          field: "Type",
          title: "問題類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.FaqType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "Question", title: "問題", formatter: ({ cellValue }) => cellValue?.substring(0, 20) },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Ordinal", order: 0 });
          return model.dispatch("faq/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch("faq/query") : undefined,
        save: model ? (params) => model.dispatch("faq/save", params) : undefined,
      },
      modalConfig: { width: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        { field: "Type", title: "問題類型", span: 12, itemRender: { name: "$select", props: { placeholder: "問題類型" }, options: model ? Object.values(model.enums.FaqType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } } },
        { field: "Question", title: "標題", span: 24, itemRender: { name: "$input", props: { placeholder: "請輸入問題" } } },
        { field: "Answer", title: "回答", span: 24, slots: { default: 'column-answer' } },
      ],
      rules: {
        Type: [{ required: true }],
        Ordinal: [{ required: true }],
        Question: [{ required: true }],
        Answer: [{ required: true }],
      }
    };

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      editorOptions
    };
  }
});
</script>


import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";

export default defineComponent({
  components: {
    Grid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});

    const gridOptions: GridOptions = {
      title: "常見問題管理",
      multiselect: false,
      canCreate: true,
      canUpdate: true,
      canDelete: true,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      columns: [
        {
          field: "Type",
          title: "問題類型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => model ? Object.values(model.enums.FaqType).find(e => e.Value === cellValue)?.Name : undefined
        },
        { field: "Question", title: "問題", formatter: ({ cellValue }) => cellValue?.substring(0, 20) },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
      ],
      promises: {
        query: model ? (params) => {
          if (!params.sortings) params.sortings = [];
          params.sortings.push({ column: "Ordinal", order: 0 });
          return model.dispatch("faq/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch("faq/query") : undefined,
        save: model ? (params) => model.dispatch("faq/save", params) : undefined,
      },
      modalConfig: { width: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      items: [
        { field: "Type", title: "問題類型", span: 12, itemRender: { name: "$select", props: { placeholder: "問題類型" }, options: model ? Object.values(model.enums.FaqType).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        { field: 'Ordinal', title: '排序', span: 12, itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } } },
        { field: "Question", title: "標題", span: 24, itemRender: { name: "$input", props: { placeholder: "請輸入問題" } } },
        { field: "Answer", title: "回答", span: 24, slots: { default: 'column-answer' } },
      ],
      rules: {
        Type: [{ required: true }],
        Ordinal: [{ required: true }],
        Question: [{ required: true }],
        Answer: [{ required: true }],
      }
    };

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      editorOptions
    };
  }
});
